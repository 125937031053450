import {html, LitElement} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {floatingButtonStyles} from "@/components/floating-button/floating-button.styles";


@customElement('sbk-floating-button')
export class SbkFloatingButton extends LitElement {

    @property()
    modalHeading = '';

    @property()
    buttonText = '';

    static get styles() {
        return floatingButtonStyles;
    }

    render() {
        return html`
            <div class="element-container">
                <sbk-modal heading=${this.modalHeading}>
                <sbk-button part="button" variant="secondary" slot="triggerElement" icon="phone">${this.buttonText}</sbk-button>
                    <div>
                        <slot></slot>
                    </div>
                </sbk-modal>
            </div>
        `;
    }
}
